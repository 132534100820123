@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // &:not(i) {
  //   font-family: "Lato", sans-serif !important;
  // }
  &:not(i) {

    font-family:'Poppins', sans-serif !important;
  }
}
html {
  background: #ebebeb;
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
button:disabled {
  cursor: not-allowed;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

i.fa.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa.fa-file-pdf-o {
  font-size: 4rem;
  width: 20px;
}
.form-group {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: 1px solid;
    margin: 0 0.4rem;
  }
}
.form-action {
  display: flex;
  button {
    margin: auto;
  }
}
.table-container {
  display: flex;
  justify-content: center;
  margin: 2rem;
  flex-direction: column;
  label {
    margin-bottom: 0.7rem;
    font-size: 1.3rem;
}
  table {
    width: 100%;
    th, td {
      text-align: center;
    }
  }
}
.process-item {
  border: 2px solid #ccc;
    border-radius: 4px;
    box-shadow: 2px 2px 5px #ccc;
    padding: 1rem;
    h3 {
      padding-left: 2rem;
    }
}
.main-container{
  width: 50%;
  margin: 0 auto;
}
.main-container .toolkit-section{
  border-bottom: 1px solid #000;
  padding: 3rem 0;
}
.main-container .toolkit-section h3{
  font-size: 22px;
  margin: 0 0 1rem 0;
}
.main-container .toolkit-section .pdf-icon{
  font-size: 22px;
  margin-bottom: 1rem;
}
.main-container .toolkit-section p{
  font-size: 15px;
}
.main-container .toolkit-section .file-upload{
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 60%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
 
}
.main-container .toolkit-section .file-upload .upload-btn{ 
  border-right: 1px solid #ccc;
  background-color: #e5e5e5;
  padding: 3px 8px;
}
.main-container .toolkit-section .file-upload span.text{ 
  padding-left: 12px;
}
.main-container .toolkit-section .file-upload input{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
}
.main-container .toolkit-section .proc-btn{
  background-color: #243a76;
  color: #fff;
  font-size: 16px;
  border: 1px solid transparent;
  margin-top: 1.5rem;
  border-radius: 5px;
  width: 30%;
  padding: 0.3rem

}
.main-container .toolkit-section h4{
  font-size: 22px;
}
.main-container .toolkit-section h6{
  font-size: 14px;
  font-weight: 400;
}
.main-container .toolkit-section .radio-main{
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}
.main-container .toolkit-section .radio-main label{
  margin-right: 2rem;
}
.main-container .toolkit-section .radio-main input{
  margin-right:0.2rem ;
}
.main-container .toolkit-section.tool-no-border{
border: none;
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa.fa-trash {
  color: #447edf;
}